export function init() {

	var $mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider) {
		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: true,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			lazyLoad: 'ondemand',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.arrows-hld')
		});
	}

}