import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
// var jQueryBridget = require('jquery-bridget');
// var Isotope = require('isotope-layout');
// jQueryBridget( 'isotope', Isotope, $ );

import * as Lazy from 'jquery-lazy';

// function reorganizeIsotope() { 
// 	$('.isotope-grid[class*="style-modern"]').each(function(){
// 		var $container = $(this);
// 		var width = $container.find(".grid-sizer").width();
// 		var ratioheight = $container.data('heightratio');
// 		if (!ratioheight) { ratioheight = 0.8; }
// 		var spacing = 0; if ($container.hasClass("isotope-spaced") || $container.hasClass("isotope-spaced-mini")) { spacing = parseInt($container.find(".isotope-item").css('marginRight'),10); }
// 		var height = parseInt(width * ratioheight, 10);
// 		$container.children('.isotope-item').css({ 'height': height+'px' });
// 		$container.children('.isotope-item.tall, .isotope-item.wide-tall').css({ 'height': height*2+spacing+'px' });
// 		$container.isotope('layout');

// 		// adapt images
// 		$container.children('.isotope-item').each(function(i) {
// 			var imgHeight = $(this).find("img").height();
//            	var imgWidth = $(this).find("img").width();
// 			var imgRatio = imgWidth/imgHeight;
// 			var itemHeight = $(this).height();
//            	var itemWidth = $(this).width();
// 			var itemRatio = itemWidth/itemHeight;
// 			var imgClass = '';
// 			if (imgRatio < itemRatio) { imgClass = 'wide-img'; $(this).find("img").removeClass('tall-img'); }
// 			else { imgClass = 'tall-img'; $(this).find("img").removeClass('wide-img'); }
// 			$(this).find("img").addClass(imgClass);

// 			if(i > 7) {
// 				$(this).addClass('portfolio-item--hidden-on-start');
// 			}
//         });
// 	});
// }

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".gallery").lightGallery({
			selector: '.lightgallery' 
		});

		$(".offer-gallery").lightGallery({
			selector: '.lightgallery' 
		});

		$(".certificates").lightGallery({
			selector: '.lightgallery' 
		});
	}

	$(".btn--orderquote").click(function(e) {
		e.preventDefault();
		e.stopPropagation();

	    $([document.documentElement, document.body]).animate({
	        scrollTop: $(".section--form").offset().top-80
	    }, 1000);
	});

	$('.trigger-btn').on('click', function(){
		$('.main-navigation-hld').addClass('show');
	});

	$('.main-nav .menu').on('click', 'a', function(e){
		var $this = $(this);

		if($this.parent().hasClass('menu-item-has-children')){
			e.preventDefault();
			e.stopPropagation();

			$('.main-nav .menu li').removeClass('active');
			$('.main-navigation-hld').addClass('active');

			$this.parent().addClass('active');
		};
	});

	$('.back-to-main-menu').on('click', function(){
		$('.main-nav .menu li').removeClass('active');
		$('.main-navigation-hld').removeClass('active');
	});

	$('.close-main-menu').on('click', function(){
		$('.main-navigation-hld').removeClass('show');
	});

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

	document.querySelectorAll('.wpcf7 input[name="page-url"]').forEach(function(element) { element.value = window.location.href; });

	$('body').on('click', '.scroll-to a', function() {
		var topPos = 0;
		if($(this).attr("id") === "scroll-down") { topPos = $("#page-body").offset().top; }
		if($(this).parent().hasClass("scroll-to")) { 
			var href = $(this).attr('href');
			var target = $(this).attr('data-target');
			if (href.charAt(0) === '#') { target = href; }
			topPos = $(target).offset().top - $('#header').height()-30;
		}
		$('html,body').animate({ scrollTop: topPos}, 1000);
		return false;
	});

});

$(window).on('load', function() {

	// if( $('.isotope-grid').length ) {
	// 	$('.isotope-grid').each(function(){
	// 		var $container = $(this);
	// 		$(this).prepend('<div class="grid-sizer"></div>');
	// 		if (!$container.hasClass("fitrows")) { 
	// 			$container.isotope({
	// 				layoutMode: 'masonry',
	// 				itemSelector : '.isotope-item',
	// 				masonry: { columnWidth: '.grid-sizer' }
	// 			});	
	// 		} else {
	// 			$container.isotope({
	// 				layoutMode: 'fitRows',
	// 				itemSelector : '.isotope-item',
	// 				masonry: { columnWidth: '.grid-sizer' }
	// 			});	
	// 		}
	// 	});
			
	// 	$('.filter').on("click", "li a", function() { 
	// 		var thisItem = $(this),
	// 			parentul = thisItem.parents('ul.filter').data('related-grid'),
	// 			dataCategory = thisItem.attr('data-cat');

	// 		if (!parentul) {
	// 			alert('Please specify the data-related-grid');
	// 		} else {
	// 			thisItem.parents('ul.filter').find('a').removeClass('active');
	// 			thisItem.addClass('active');
	// 			var selector = thisItem.attr('data-filter');
	// 			$('#'+parentul).isotope({ filter: selector }, function(){ });
	// 			var elems = $('#'+parentul).isotope('getFilteredItemElements');

	// 		}
			
	// 		return false;
	// 	});
		
		// reorganizeIsotope();

		// if($('.isotope-grid').length) {
		// 	setTimeout(function(){
		// 		$('.isotope-grid').isotope('layout');
		// 	}, 0);
		// }
	// }
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

});

// $(window).resize(function() { 
// 	if( $('.isotope-grid').length ) { reorganizeIsotope(); }
// });